import React from 'react'
import { SectionImagesMarquee } from '../../../types/sections'
import { Box, Button, Flex, Heading, Image } from '../../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { SECTION_MARGIN } from '../../../utils/constants'
import { ContentMd } from '../../commons'
import Marquee from 'react-fast-marquee'

function ImagesMarquee(props: SectionImagesMarquee): JSX.Element {
  const { id, sectionId, headline, content, buttons, images } = props
  return (
    <Box as={'section'} id={sectionId || id} my={SECTION_MARGIN}>
      <Grid>
        <Row center={'xs'}>
          <Col xs={12} md={10} lg={8}>
            <Heading as={'h2'} type={'heading1'} textAlign={'center'}>
              {headline}
            </Heading>
            {content && (
              <Box mt={[4, 4, 5]} textAlign={'center'}>
                <ContentMd content={content} />
              </Box>
            )}
            {buttons.length > 0 && (
              <Flex mt={[4, 4, 5]} width={'100%'} justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'}>
                {buttons.map((button) => {
                  return <Button key={button.id} {...button} my={2} mx={2} />
                })}
              </Flex>
            )}
          </Col>
        </Row>
      </Grid>

      <Box mt={[8, 9, 10]}>
        <Marquee direction={'right'} gradient={false}>
          {images.slice(0, images.length / 2).map((image, idx) => {
            return (
              <Box
                key={`part-1-${idx}`}
                mx={[1, 1, 3]}
                width={[160, 240, 420]}
                style={{ aspectRatio: '1.5' }}
                borderRadius={'small'}
                overflow={'hidden'}
              >
                <Image
                  alt={image.alt ?? ''}
                  title={image.title ?? ''}
                  image={image.gatsbyImageData}
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
            )
          })}
        </Marquee>
      </Box>
      <Box mt={[2, 2, 5]}>
        <Marquee direction={'left'} gradient={false}>
          {images.slice(images.length / 2, images.length).map((image, idx) => {
            return (
              <Box
                key={`part-2-${idx}`}
                mx={[1, 1, 3]}
                width={[160, 240, 420]}
                style={{ aspectRatio: '1.5' }}
                borderRadius={'small'}
                overflow={'hidden'}
              >
                <Image
                  alt={image.alt ?? ''}
                  title={image.title ?? ''}
                  image={image.gatsbyImageData}
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
            )
          })}
        </Marquee>
      </Box>
    </Box>
  )
}

export default ImagesMarquee
